import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const GET_FAMILLE = gql`
  query getFamille($id: uuid) {
    Mjc_famille(where: { id: { _eq: $id } }) {
      id
      nom
      prenom
      adresse
      adresseComplementaire
      codePostal
      ville
      ville_id
      villeNormalisee {
        nom
        inse
      }
      email
      email2
      telFixe
      telPortable
      telPortable2
      telUrgence
      mailing
      avoir
      avoirMotif
      adherents {
        adhesion {
          nom
          montant
          id
        }
        id
        nom
        prenom
        dateNaissance
        adhesion_id
        reduction_id
        pratiques {
          activite_detail_id
          adherent_id
          activite_detail {
            id
            activite {
              nom
              tarifComplement
              texteTarifComplement
            }
            horaireDebut
            horaireFin
            lieu
            montant
            montantComplement
            professeur {
              nom
              prenom
            }
            publicComplement
            type_public
            semaineJour
            licence
            licenceTxt
          }
        }
        reduction {
          id
          nom
        }
      }
      paiements {
        id
        type
        montant
        date
      }
    }
  }
`;

export const useGetFamille = (id) => {
  const { loading, data, error } = useQuery(GET_FAMILLE, { variables: { id }, pollInterval: 1000 });

  return {
    loading,
    famille: data && data.Mjc_famille ? data.Mjc_famille[0] : null,
    error,
  };
};
