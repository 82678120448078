import gql from "graphql-tag";

const GET_FAMILLES = gql`
  query getFamilles {
    Mjc_famille(limit: 10, order_by: { createdAt: desc }) {
      id
      nom
      prenom
      adresse
      adresseComplementaire
      codePostal
      ville
      email
      email2
      telFixe
      telPortable
      telPortable2
      telUrgence
      hopital
      mailing
      photo
      avoir
      avoirMotif
    }
  }
`;

const FIND_FAMILLES = gql`
  query findFamilles($pattern: String) {
    Mjc_famille(where: {_or: [{nom: {_ilike: $pattern}}, {email: {_ilike: $pattern}}, {email2: {_ilike: $pattern}}]}) {
      id
      nom
      prenom
      adresse
      adresseComplementaire
      codePostal
      ville
      email
      email2
      telFixe
      telPortable
      telPortable2
      telUrgence
      hopital
      mailing
      photo
      avoir
      avoirMotif
    }
  }
`;

const GET_MAILING = gql`
  query filterByMailing {
    Mjc_famille {
      mailing
      email
      adherents {
        pratiques {
          activite_detail_id
        }
      }
    }
  }
`;

const GET_PAIEMENTS = gql`
  query gePaiements {
    Mjc_paiement {
      date
      montant
      type
      famille {
        nom
        prenom
      }
    }
  }
`;

const GET_ACTIVITES = gql`
  query getActivites {
    Mjc_activite {
      id
      nom
      description
      categorie
    }
  }
`;

const INSERT_ACTIVITE = gql`
  mutation insertActivite(
    $id: uuid
    $nom: String
    $description: String
    $categorie: String
    $tarifComplement: Float
    $texteTarifComplement: String
  ) {
    insert_Mjc_activite(
      objects: {
        id: $id
        nom: $nom
        description: $description
        categorie: $categorie
        tarifComplement: $tarifComplement
        texteTarifComplement: $texteTarifComplement
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_ACTIVITE = gql`
  mutation updateActivite($id: uuid, $nom: String, $description: String) {
    update_Mjc_activite(
      where: { id: { _eq: $id } }
      _set: { nom: $nom, description: $description }
    ) {
      affected_rows
    }
  }
`;

const GET_PROFESSEURS = gql`
  query getProfesseurs {
    Mjc_professeur(order_by: { updatedAt: desc }) {
      id
      nom
      prenom
      activites {
        id
      }
    }
  }
`;

const INSERT_PROFESSEUR = gql`
  mutation insertProfesseur(
    $id: uuid
    $nom: String
    $prenom: String
    $createdAt: timestamptz
    $updatedAt: timestamptz
  ) {
    insert_Mjc_professeur(
      objects: {
        id: $id
        nom: $nom
        prenom: $prenom
        createdAt: $createdAt
        updatedAt: $updatedAt
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_PROFESSEUR = gql`
  mutation updateProfesseur(
    $id: uuid
    $nom: String
    $prenom: String
    $updatedAt: timestamptz
  ) {
    update_Mjc_professeur(
      where: { id: { _eq: $id } }
      _set: { nom: $nom, prenom: $prenom, updatedAt: $updatedAt }
    ) {
      affected_rows
    }
  }
`;

const DELETE_PROFESSEUR = gql`
  mutation deleteProfesseur($id: uuid) {
    delete_Mjc_professeur(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_LIEUX = gql`
  query getLieux {
    Mjc_lieu {
      lieu
    }
  }
`;

const GET_REDUCTIONS = gql`
  query getReductions {
    Mjc_reduction {
      id
      nom
    }
  }
`;

const GET_ADHESIONS = gql`
  query getAdhesions {
    Mjc_adhesion {
      id
      montant
      nom
    }
  }
`;

const GET_TYPES_PUBLIC = gql`
  query getTypesPublic {
    Mjc_type_public {
      type
    }
  }
`;
const GET_TYPES_PAIEMENT = gql`
  query getTypesPaiement {
    Mjc_type_paiment {
      type
    }
  }
`;

const INSERT_PAIEMENT = gql`
  mutation insertPaiement(
    $id: uuid
    $date: String
    $type: String
    $montant: Float
    $famille_id: uuid
  ) {
    insert_Mjc_paiement(
      objects: {
        id: $id
        date: $date
        type: $type
        montant: $montant
        famille_id: $famille_id
      }
    ) {
      affected_rows
    }
  }
`;

const INSERT_ADHERENT = gql`
  mutation insertAdherent(
    $id: uuid
    $nom: String
    $prenom: String
    $dateNaissance: String
    $famille_id: uuid
    $adhesion_id: uuid
    $reduction_id: uuid
  ) {
    insert_Mjc_adherent(
      objects: {
        id: $id
        nom: $nom
        prenom: $prenom
        famille_id: $famille_id
        adhesion_id: $adhesion_id
        reduction_id: $reduction_id
        dateNaissance: $dateNaissance
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_ADHERENT = gql`
  mutation insertAdherent(
    $id: uuid
    $nom: String
    $prenom: String
    $dateNaissance: String
    $famille_id: uuid
    $adhesion_id: uuid
    $reduction_id: uuid
  ) {
    update_Mjc_adherent(
      where: { id: { _eq: $id } }
      _set: {
        nom: $nom
        prenom: $prenom
        famille_id: $famille_id
        adhesion_id: $adhesion_id
        reduction_id: $reduction_id
        dateNaissance: $dateNaissance
      }
    ) {
      affected_rows
    }
  }
`;

const INSERT_FAMILLE = gql`
  mutation insertFamille(
    $id: uuid
    $nom: String
    $prenom: String
    $adresse: String
    $adresseComplementaire: String
    $codePostal: String
    $ville: String
    $telFixe: String
    $telPortable: String
    $telPortable2: String
    $telUrgence: String
    $email: String
    $email2: String
    $hopital: String
    $photo: Boolean
    $mailing: Boolean
    $avoir: Float
    $avoirMotif: String
  ) {
    insert_Mjc_famille(
      objects: {
        id: $id
        nom: $nom
        prenom: $prenom
        adresse: $adresse
        adresseComplementaire: $adresseComplementaire
        codePostal: $codePostal
        ville: $ville
        telFixe: $telFixe
        telPortable: $telPortable
        telPortable2: $telPortable2
        telUrgence: $telUrgence
        email: $email
        email2: $email2
        hopital: $hopital
        photo: $photo
        mailing: $mailing
        avoir: $avoir
        avoirMotif: $avoirMotif
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const UPDATE_FAMILLE = gql`
  mutation updateFamille(
    $id: uuid
    $nom: String
    $prenom: String
    $adresse: String
    $adresseComplementaire: String
    $codePostal: String
    $ville: String
    $telFixe: String
    $telPortable: String
    $telPortable2: String
    $telUrgence: String
    $email: String
    $email2: String
    $hopital: String
    $photo: Boolean
    $mailing: Boolean
    $avoir: Float
    $avoirMotif: String
  ) {
    update_Mjc_famille(
      where: { id: { _eq: $id } }
      _set: {
        nom: $nom
        prenom: $prenom
        adresse: $adresse
        adresseComplementaire: $adresseComplementaire
        codePostal: $codePostal
        ville: $ville
        telFixe: $telFixe
        telPortable: $telPortable
        telPortable2: $telPortable2
        telUrgence: $telUrgence
        email: $email
        email2: $email2
        hopital: $hopital
        photo: $photo
        mailing: $mailing
        avoir: $avoir
        avoirMotif: $avoirMotif
      }
    ) {
      affected_rows
    }
  }
`;

const INSERT_PROGRAMMATION = gql`
  mutation insertProgrammation(
    $id: uuid
    $horaireDebut: String
    $horaireFin: String
    $semaineJour: String
    $montant: Float
    $montantComplement: String
    $type_public: String
    $lieu: String
    $activite_id: uuid
    $professeur_id: uuid
    $publicComplement: String
    $licence: Float
    $licenceTxt: String
  ) {
    insert_Mjc_activite_detail(
      objects: {
        id: $id
        horaireDebut: $horaireDebut
        horaireFin: $horaireFin
        semaineJour: $semaineJour
        montant: $montant
        montantComplement: $montantComplement
        type_public: $type_public
        publicComplement: $publicComplement
        lieu: $lieu
        activite_id: $activite_id
        professeur_id: $professeur_id
        licence: $licence
        licenceTxt: $licenceTxt
      }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_PROGRAMMATION = gql`
  mutation updateProgrammation(
    $id: uuid
    $horaireDebut: String
    $horaireFin: String
    $semaineJour: String
    $montant: Float
    $montantComplement: String
    $type_public: String
    $lieu: String
    $activite_id: uuid
    $professeur_id: uuid
    $publicComplement: String
    $licence: Float
    $licenceTxt: String
  ) {
    update_Mjc_activite_detail(
      where: { id: { _eq: $id } }
      _set: {
        id: $id
        horaireDebut: $horaireDebut
        horaireFin: $horaireFin
        semaineJour: $semaineJour
        montant: $montant
        montantComplement: $montantComplement
        type_public: $type_public
        publicComplement: $publicComplement
        lieu: $lieu
        activite_id: $activite_id
        professeur_id: $professeur_id
        licence: $licence
        licenceTxt: $licenceTxt
      }
    ) {
      affected_rows
    }
  }
`;

const DELETE_PROGRAMMATION = gql`
  mutation deleteProgrammation($id: uuid) {
    delete_Mjc_activite_detail(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const INSERT_PRATIQUE = gql`
  mutation insertPratique($adherentId: uuid, $activiteDetailId: uuid) {
    insert_Mjc_pratique(
      objects: {
        activite_detail_id: $activiteDetailId
        adherent_id: $adherentId
      }
    ) {
      affected_rows
      returning {
        adherent_id
        activite_detail_id
        activite_detail {
          activite {
            nom
          }
        }
      }
    }
  }
`;

const DELETE_PRATIQUE = gql`
  mutation deletePratique($adherentId: uuid, $activiteDetailId: uuid) {
    delete_Mjc_pratique(
      where: {
        activite_detail_id: { _eq: $activiteDetailId }
        _and: {}
        adherent_id: { _eq: $adherentId }
      }
    ) {
      affected_rows
    }
  }
`;

const DELETE_PAIEMENT = gql`
  mutation deletePratique($id: uuid) {
    delete_Mjc_paiement(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_FAMILLE = gql`
  query getFamille($id: uuid) {
    Mjc_famille(where: { id: { _eq: $id } }) {
      id
      nom
      prenom
      adresse
      adresseComplementaire
      codePostal
      ville
      email
      email2
      telFixe
      telPortable
      telPortable2
      telUrgence
      mailing
      avoir
      avoirMotif
      adherents {
        adhesion {
          nom
          montant
          id
        }
        id
        nom
        prenom
        dateNaissance
        adhesion_id
        reduction_id
        pratiques {
          activite_detail_id
          adherent_id
          activite_detail {
            id
            activite {
              nom
              tarifComplement
              texteTarifComplement
            }
            horaireDebut
            horaireFin
            lieu
            montant
            montantComplement
            professeur {
              nom
              prenom
            }
            publicComplement
            type_public
            semaineJour
            licence
            licenceTxt
          }
        }
        reduction {
          id
          nom
        }
      }
      paiements {
        id
        type
        montant
        date
      }
    }
  }
`;

const GET_DETAILS_ACTIVITES = gql`
  query getMyTodos {
    Mjc_activite_detail {
      id
      activite {
        nom
        tarifComplement
        texteTarifComplement
      }
      horaireDebut
      horaireFin
      lieu
      montant
      montantComplement
      professeur {
        nom
        prenom
      }
      professeur_id
      publicComplement
      type_public
      semaineJour
      licence
      licenceTxt
    }
  }
`;

const GET_ADHERENTS_BY_ACTIVITE = gql`
  query getAdherentsByActivite($activite_id: uuid) {
    Mjc_activite_detail(where: { activite_id: { _eq: $activite_id } }) {
      id
      semaineJour
      horaireDebut
      horaireFin
      pratiques {
        activite_detail_id
        adherent {
          id
          nom
          prenom
          famille {
            email
            telUrgence
            telPortable
          }
        }
      }
      activite {
        nom
      }
    }
  }
`;

const GET_DETAILS_ACTIVITES_BY_ACTIVITE = gql`
  query getProgrammation($activite_id: uuid) {
    Mjc_activite_detail(where: { activite_id: { _eq: $activite_id } }) {
      id
      activite {
        nom
        tarifComplement
        texteTarifComplement
      }
      horaireDebut
      horaireFin
      lieu
      montant
      montantComplement
      professeur {
        nom
        prenom
      }
      professeur_id
      publicComplement
      type_public
      semaineJour
      licence
      licenceTxt
    }
  }
`;

const GET_FAMILLE_PAIEMENTS = gql`
  query getProgrammation($famille_id: uuid) {
    Mjc_paiement(where: { famille_id: { _eq: $famille_id } }) {
      id
      date
      type
      montant
    }
  }
`;

const INSERT_MAILING = gql`
  mutation insertMailing(
    $objet: String
    $message: String
    $pieces_jointes: String
    $envoyer: Boolean
    $envois: Mjc_envoi_arr_rel_insert_input
  ) {
    insert_Mjc_mailing(
      objects: {
        objet: $objet
        message: $message
        pieces_jointes: $pieces_jointes
        envoyer: $envoyer
        envois: $envois
      }
    ) {
      affected_rows
    }
  }
`;

export default {
  GET_FAMILLES,
  FIND_FAMILLES,
  GET_FAMILLE,
  UPDATE_FAMILLE,
  INSERT_FAMILLE,
  INSERT_ADHERENT,
  INSERT_PAIEMENT,
  DELETE_PAIEMENT,
  UPDATE_ADHERENT,
  GET_DETAILS_ACTIVITES,
  INSERT_PRATIQUE,
  DELETE_PRATIQUE,
  GET_ACTIVITES,
  GET_ADHERENTS_BY_ACTIVITE,
  INSERT_ACTIVITE,
  UPDATE_ACTIVITE,
  GET_ADHESIONS,
  GET_REDUCTIONS,
  GET_PAIEMENTS,
  GET_DETAILS_ACTIVITES_BY_ACTIVITE,
  GET_PROFESSEURS,
  INSERT_PROFESSEUR,
  UPDATE_PROFESSEUR,
  DELETE_PROFESSEUR,
  GET_LIEUX,
  GET_TYPES_PAIEMENT,
  GET_TYPES_PUBLIC,
  INSERT_PROGRAMMATION,
  UPDATE_PROGRAMMATION,
  DELETE_PROGRAMMATION,
  GET_FAMILLE_PAIEMENTS,
  GET_MAILING,
  INSERT_MAILING
};
